<template>
  <div class="view-container bg-login-background bg-left bg-cover">
    <div class="content-container">
      <h1
        class="main-title mb-12 uppercase overflow-hidden font-extrabold leading-tight font-pragmatica-extended xl:mb-7rem"
      >
        {{ $t('hsc.login.title') }}
      </h1>
      <div
        class="flex flex-col items-start justify-between xl:flex-row xl:items-start"
      >
        <div class="mb-12 md:mb-16 xl:w-2/5 xl:mb-0 xl:pt-8">
          <h2
            class="main-subtitle mb-12 leading-tight font-pragmatica-extended"
          >
            {{ $t('hsc.login.subtitle') }}
          </h2>
          <p class="text-xl leading-loose md:text-1.6rem">
            {{ $t('hsc.login.description') }}
          </p>
        </div>
        <controller-login
          v-slot="{ form, submit, status, generateErrorMessage }"
          class="self-center w-11/12 px-5 pt-5 pb-6 bg-whiteSmoke rounded-lg xl:w-1/2 sm:w-4/6 md:px-8 md:pt-8 md:pb-10 lg:w-3/5"
        >
          <form @submit.prevent="submit">
            <section class="mb-5">
              <label class="form-label">
                {{ $t('hsc.form.inputs.email') }}*
                <input
                  v-model.trim="form.username"
                  type="email"
                  class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                  :placeholder="$t('hsc.form.inputs.email')"
                />
              </label>
              <p
                v-show="generateErrorMessage('form.username')"
                class="error-msg"
              >
                {{ generateErrorMessage('form.username') }}
              </p>
            </section>
            <section class="mb-5">
              <label class="form-label">
                {{ $t('hsc.form.inputs.password') }}*
                <div class="relative">
                  <input
                    v-model.trim="form.password"
                    :type="passwordVisible ? 'text' : 'password'"
                    class="form-text password-input-padding form-input placeholder-lightGrayishBlue focus:outline-none"
                    :placeholder="$t('hsc.form.inputs.password')"
                  />
                  <fa
                    :icon="
                      passwordVisible
                        ? `${icons.basic.eyeSlash}`
                        : `${icons.basic.eye}`
                    "
                    class="password-eye-icon transitioned"
                    @click.stop="togglePasswordVisibility"
                  />
                </div>
              </label>
              <p
                v-show="generateErrorMessage('form.password')"
                class="error-msg"
              >
                {{ generateErrorMessage('form.password') }}
              </p>
            </section>
            <section class="mb-10 flex form-text md:mb-4.5rem">
              <p>
                {{ $t('hsc.login.forgotPassword') }}
                <cms-link :to="$Page.ForgotPassword">
                  <span
                    class="font-bold font-pragmatica-extended transitioned hover:text-pureYellow"
                    >{{ $t('hsc.login.resetNow') }}</span
                  >
                </cms-link>
              </p>
            </section>
            <section>
              <multiple-bordered-button
                primary-color-name="darkGrayishViolet"
                text-color-name="white"
                is-submit
                :disabled="status.pending"
              >
                <span class="btn-text">{{
                  $t('hsc.form.actions.signIn')
                }}</span>
              </multiple-bordered-button>
            </section>
          </form>
        </controller-login>
      </div>
    </div>
  </div>
</template>

<script>
import ControllerLogin from 'Controllers/user/ControllerLogin'
import MultipleBorderedButton from '@/components/ui/buttons/MultipleBorderedButton'
import FaIcons from '@/mixins/FaIcons'
export default {
  components: {
    ControllerLogin,
    MultipleBorderedButton,
  },
  mixins: [FaIcons],
  data() {
    return {
      passwordVisible: false,
    }
  },

  methods: {
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible
    },
  },
}
</script>
