<template>
  <controller-header v-click-outside="closeMobileMenu">
    <template #bottom="{ availableLocales, switchLanguage }">
      <div class="w-full bg-white shadow-lg">
        <!-- Main navbar section-->
        <div
          class="w-full flex items-center justify-between px-4f py-4 shadow-lg text-lg md:px-10 md:text-1.4rem xl:grid xl:grid-cols-3"
        >
          <button
            class="flex items-center justify-start h-12 w-24 md:h-20 md:w-40 focus:outline-none"
            @click="handleLogoClick"
          >
            <cms-image
              alt="Logo"
              id="hsc-navbar-logo"
              :link="$siteLogo"
              class="w-full h-full"
            />
          </button>
          <ControllerNavigationBar
            v-slot="{ item }"
            tag="div"
            class="hidden items-center justify-center xl:flex"
          >
            <ControllerNavigationItem
              :data-qa="item.id"
              tag="div"
              class="mx-8 inline-block font-pragmatica-extended font-extrabold tracking-0.14rem uppercase"
              inner-class="block navigation-item hover:text-pureYellow transitioned"
            />
          </ControllerNavigationBar>

          <div class="flex items-center justify-end">
            <cms-link
              v-if="isLoginPage && !$auth.loggedIn"
              :to="$Page.Register"
              class="navigation-item font-pragmatica-extended font-extrabold transitioned hover:text-pureYellow focus:outline-none"
              @click.native="closeMobileMenu"
              >{{ $t('hsc.header.register') }}</cms-link
            >

            <cms-link
              v-else-if="!isLoginPage && !$auth.loggedIn"
              :to="$Page.Login"
              class="navigation-item font-pragmatica-extended font-extrabold transitioned hover:text-pureYellow focus:outline-none"
              @click.native="closeMobileMenu"
              >{{ $t('hsc.header.login') }}</cms-link
            >

            <button
              v-else
              class="font-pragmatica-extended font-extrabold transitioned hover:text-pureYellow focus:outline-none"
              @click.stop="$auth.logout()"
            >
              {{ $t('hsc.header.logout') }}
            </button>

            <cms-link
              :to="$Page.Cart"
              class="relative navigation-item px-4 py-1 ml-4 uppercase rounded-full bg-pureYellow border border-pureYellow font-pragmatica-extended font-extrabold tracking-0.14rem transitioned hover:bg-transparent hover:border-black focus:outline-none md:px-6 md:ml-12"
              @click.native="closeMobileMenu"
            >
              <p class="hidden sm:block">{{ $t('hsc.header.cart') }}</p>
              <fa :icon="icons.basic.shoppingCart" class="block sm:hidden" />
              <div
                v-if="$cart.items.length"
                class="absolute top-neg.5rem right-neg.5rem w-6 h-6 rounded-full flex items-center justify-center bg-red-700 font-pragmatica font-semibold leading-none text-white text-sm md:top-neg1rem md:right-neg1rem md:w-8 md:h-8 md:text-lg"
              >
                {{ $cart.items.length }}
              </div>
            </cms-link>

            <cms-link
              v-if="$auth.loggedIn"
              :to="$Page.Profile"
              class="navigation-item ml-8 md:ml-12 transitioned hover:text-pureYellow"
              @click.native="closeMobileMenu"
            >
              <fa :icon="icons.basic.user" />
            </cms-link>

            <select
              :value="$i18n.locale"
              class="pl-3 ml-4 bg-transparent border-l border-gray-400 font-medium uppercase tracking-0.14rem cursor-pointer focus:outline-none md:pl-6 md:ml-1.8rem"
              @change="switchLanguage($event.target.value)"
            >
              <option
                v-for="locale in availableLocales"
                :key="locale.code"
                :value="locale.code"
              >
                {{ locale.code }}
              </option>
            </select>
          </div>

          <div class="flex items-center justify-end xl:hidden">
            <button
              id="navbar-hamburger-btn"
              class="focus:outline-none"
              @click="toggleMobileMenu"
            >
              <img
                v-if="mobileMenuActive"
                src="/svg/close-icon.svg"
                class="menu-icon"
              />
              <img v-else src="/svg/hamburger-icon.svg" class="menu-icon" />
            </button>
          </div>
        </div>

        <!--Mobile menu section-->
        <transition name="dropdown-ease-in-out">
          <div
            v-show="mobileMenuActive"
            class="w-full text-xl bg-white overflow-y-auto md:text-1.4rem xl:hidden"
            :class="`${
              mobileMenuActive
                ? 'max-h-small-mobile-menu md:max-h-mobile-menu'
                : 'max-h-0'
            }`"
          >
            <ControllerNavigationBar
              v-click-outside="closeMobilePanel"
              tag="ul"
              class="w-2/3 mx-auto font-pragmatica-extended sm:w-1/2 lg:w-1/3"
            >
              <template #list="{ items }">
                <template v-for="item in items">
                  <!-- If navigation item has no children just create a link-->
                  <cms-link
                    v-if="!item.item.children.length"
                    :key="item.item.id"
                    :to="item.item"
                    class="navigation-item navigation-mobile-link transitioned bg-pureYellow hover:bg-darkGrayishViolet hover:text-pureYellow"
                    @click.native="closeMobileMenu"
                  >
                    {{ item.item.title }}
                  </cms-link>
                  <!-- If navigation item has children, create a dropdown-->
                  <li
                    v-else
                    :key="item.item.id"
                    :data-qa="item.item.id"
                    class="cursor-pointer navigation-mobile-link transitioned bg-pureYellow hover:bg-darkGrayishViolet hover:text-pureYellow"
                    @click="toggleMobilePanel(item.item)"
                  >
                    {{ item.item.title }}
                  </li>
                  <transition :key="item.item.id" name="dropdown-linear">
                    <div
                      v-show="activeNavigationItemId === item.item.id"
                      class="w-full text-lg bg-whiteSmoke rounded-lg uppercase overflow-y-auto overflow-x-hidden md:text-xl xl:hidden"
                      :class="`${
                        activeNavigationItemId === item.item.id
                          ? 'max-h-screen'
                          : 'max-h-0'
                      }`"
                    >
                      <cms-link
                        v-for="child in item.item.children"
                        :key="'mobile-panel-item-' + child.id"
                        class="navigation-item block p-1 my-2 text-center transitioned hover:text-pureYellow hover:bg-darkGrayishViolet"
                        :to="child"
                        @click.native="closeMobileMenu"
                      >
                        {{ child.title }}
                      </cms-link>
                    </div>
                  </transition>
                </template>
              </template>
            </ControllerNavigationBar>
          </div>
        </transition>
        <!--Notification section -->
        <div
          class="w-full h-12 flex items-center justify-center bg-pureYellow shadow-inner md:h-3.5rem"
        >
          <p
            class="uppercase font-pragmatica-extended text-center tracking-0.14rem text-base md:text-1.4rem"
          >
            {{ $t('hsc.header.worldwideShipping') }}
          </p>
        </div>
      </div>
    </template>
    <template #panel="{ items }">
      <div
        class="hidden items-center justify-center shadow-lg w-full p-3 bg-white text-center sm:p-5 xl:flex"
      >
        <div
          v-for="(item, index) in items"
          :key="'panel-item-' + item.id"
          class="h-full w-48 box-content border-gray-200 px-6 text-center md:w-17rem"
          :class="[index !== 0 ? 'border-l-2' : '']"
        >
          <cms-link
            :to="item.type === 'product' ? item.linkV2 : item"
            class="flex flex-col space-y-2 items-center h-full justify-center navigation-item text-base font-bold transitioned focus:outline-none hover:text-pureYellow md:text-1.4rem"
          >
            <cms-image
              :media="
                item.product && item.product.media
                  ? item.product.media
                  : item.post_media
                  ? item.post_media
                  : null
              "
              class="block mb-1 mx-auto h-7.5rem w-7.5rem object-contain object-center"
            />
            <p>{{ item.title }}</p>
          </cms-link>
        </div>
      </div>
    </template>
  </controller-header>
</template>

<script>
import ControllerHeader from 'Controllers/ControllerHeader'
import ControllerNavigationBar from 'Controllers/ControllerNavigationBar'
import ControllerNavigationItem from 'Controllers/ControllerNavigationItem'
import vClickOutside from 'v-click-outside'

import FaIcons from '@/mixins/FaIcons'
import debounce from 'lodash.debounce'

export default {
  components: {
    ControllerHeader,
    ControllerNavigationBar,
    ControllerNavigationItem,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  mixins: [FaIcons],

  data() {
    return {
      mobileMenuActive: false,
      activeNavigationItemId: null,
    }
  },

  computed: {
    isLoginPage() {
      return this.$route.name?.split('_')[0] === this.$Page.Login
    },
    isHomePage() {
      return this.$route.path === '/'
    },
  },
  mounted() {
    if (process.browser) this.addEventListeners()
  },
  beforeDestroy() {
    if (process.browser) this.removeEventListeners()
  },

  methods: {
    addEventListeners() {
      window.addEventListener('scroll', this.emitScrollToAll)
      window.addEventListener('resize', this.emitResizeToAll)
    },
    removeEventListeners() {
      window.removeEventListener('scroll', this.emitScrollToAll)
      window.removeEventListener('resize', this.emitResizeToAll)
    },
    emitScrollToAll: debounce(function () {
      this.$nuxt.$emit('custom-scroll')
    }, 250),

    emitResizeToAll: debounce(function () {
      this.$nuxt.$emit('custom-resize')
    }, 250),

    handleLogoClick() {
      this.closeMobileMenu()
      if (this.isHomePage) {
        this.scrollToTop()
        return
      }
      this.goToHomePage()
    },
    toggleMobileMenu() {
      if (this.mobileMenuActive) this.closeMobileMenu()
      else this.openMobileMenu()
    },
    openMobileMenu() {
      this.mobileMenuActive = true
    },
    closeMobileMenu() {
      this.mobileMenuActive = false
      this.closeMobilePanel()
    },
    toggleMobilePanel(selectedItem) {
      if (
        this.activeNavigationItemId === selectedItem.id ||
        !selectedItem.children.length
      ) {
        this.closeMobilePanel()
      } else {
        this.openMobilePanel(selectedItem)
      }
    },
    openMobilePanel(selectedItem) {
      this.activeNavigationItemId = selectedItem.id
    },
    closeMobilePanel() {
      this.activeNavigationItemId = null
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    goToHomePage() {
      this.$router.push('/')
    },
  },
}
</script>
<style scoped>
.menu-icon {
  @apply w-10 h-10;
}
::v-deep #hsc-navbar-logo img {
  @apply w-full h-full;
}

.navigation-mobile-link {
  @apply block py-3 my-3 rounded-lg text-center uppercase tracking-0.14rem font-extrabold shadow-lg;
}

::v-deep .nuxt-link-exact-active.navigation-item {
  @apply pointer-events-none opacity-50 !important;
}

@media only screen and (min-width: 640px) {
  .navigation-mobile-link {
    @apply my-5;
  }
}
</style>
