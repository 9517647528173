import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9abea1c0 = () => interopDefault(import('./gb-cms/components/pages/AdminLogin.vue' /* webpackChunkName: "" */))
const _70a68bda = () => interopDefault(import('./gb-cms/components/pages/PageCart.js' /* webpackChunkName: "" */))
const _44b38674 = () => interopDefault(import('./gb-cms/components/pages/PageCheckout.js' /* webpackChunkName: "" */))
const _97a41c84 = () => interopDefault(import('./gb-cms/components/pages/PageConfirmEmail.js' /* webpackChunkName: "" */))
const _5d91a0c8 = () => interopDefault(import('./gb-cms/components/pages/PageForgotPassword.js' /* webpackChunkName: "" */))
const _1c68a54b = () => interopDefault(import('./gb-cms/components/pages/PageLogin.js' /* webpackChunkName: "" */))
const _6407006a = () => interopDefault(import('./gb-cms/components/pages/PageProfile.js' /* webpackChunkName: "" */))
const _1e6b8955 = () => interopDefault(import('../pages/redirection.vue' /* webpackChunkName: "pages/redirection" */))
const _67e54bf7 = () => interopDefault(import('./gb-cms/components/pages/PageRegister.js' /* webpackChunkName: "" */))
const _4133ff92 = () => interopDefault(import('./gb-cms/components/pages/PageSearch.js' /* webpackChunkName: "" */))
const _a1746a36 = () => interopDefault(import('../node_modules/@gauss/cms-core/lib/Sitemap.js' /* webpackChunkName: "" */))
const _56efaa18 = () => interopDefault(import('./gb-cms/components/pages/PageSocialLogin.js' /* webpackChunkName: "" */))
const _1d740332 = () => interopDefault(import('./gb-cms/components/pages/PageAdminCache.js' /* webpackChunkName: "" */))
const _d8cdfb62 = () => interopDefault(import('./gb-cms/components/pages/PageAdminConsent.js' /* webpackChunkName: "" */))
const _3ca95250 = () => interopDefault(import('./gb-cms/components/pages/PageAdminTemplates.js' /* webpackChunkName: "" */))
const _3a4d5fd2 = () => interopDefault(import('./gb-cms/components/pages/PageCheckoutSuccess.js' /* webpackChunkName: "" */))
const _2ec580b8 = () => interopDefault(import('./gb-cms/components/pages/PageAdminConsentSlug.js' /* webpackChunkName: "" */))
const _9728ef0c = () => interopDefault(import('./gb-cms/components/pages/PageEvent.js' /* webpackChunkName: "" */))
const _4e557018 = () => interopDefault(import('./gb-cms/components/pages/PageBlog.js' /* webpackChunkName: "" */))
const _78322ec5 = () => interopDefault(import('./gb-cms/components/pages/PageProduct.js' /* webpackChunkName: "" */))
const _6a5fd778 = () => interopDefault(import('./gb-cms/components/pages/PageShop.js' /* webpackChunkName: "" */))
const _029bcd16 = () => interopDefault(import('./gb-cms/components/pages/PageDynamic.js' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _9abea1c0,
    name: "admin___en"
  }, {
    path: "/adminLogin",
    component: _9abea1c0,
    name: "admin-login___en"
  }, {
    path: "/cart",
    component: _70a68bda,
    name: "page-cart___en"
  }, {
    path: "/checkout",
    component: _44b38674,
    name: "page-checkout___en"
  }, {
    path: "/confirm-email",
    component: _97a41c84,
    name: "page-confirm-email___en"
  }, {
    path: "/forgot-password",
    component: _5d91a0c8,
    name: "page-forgot-password___en"
  }, {
    path: "/login",
    component: _1c68a54b,
    name: "page-login___en"
  }, {
    path: "/profile",
    component: _6407006a,
    name: "page-profile___en"
  }, {
    path: "/redirection",
    component: _1e6b8955,
    name: "redirection___en"
  }, {
    path: "/register",
    component: _67e54bf7,
    name: "page-register___en"
  }, {
    path: "/search",
    component: _4133ff92,
    name: "page-search___en"
  }, {
    path: "/sitemap.xml",
    component: _a1746a36,
    name: "sitemap.xml___en"
  }, {
    path: "/social-login",
    component: _56efaa18,
    name: "page-social-login___en"
  }, {
    path: "/admin/cache",
    component: _1d740332,
    name: "admin-cache___en"
  }, {
    path: "/admin/consent",
    component: _d8cdfb62,
    name: "admin-consent___en"
  }, {
    path: "/admin/templates",
    component: _3ca95250,
    name: "admin-templates___en"
  }, {
    path: "/checkout/success",
    component: _3a4d5fd2,
    name: "page-checkout-success___en"
  }, {
    path: "/hr/admin",
    component: _9abea1c0,
    name: "admin___hr"
  }, {
    path: "/hr/adminLogin",
    component: _9abea1c0,
    name: "admin-login___hr"
  }, {
    path: "/hr/kosarica",
    component: _70a68bda,
    name: "page-cart___hr"
  }, {
    path: "/hr/naplata",
    component: _44b38674,
    name: "page-checkout___hr"
  }, {
    path: "/hr/potvrdi-email",
    component: _97a41c84,
    name: "page-confirm-email___hr"
  }, {
    path: "/hr/pretraga",
    component: _4133ff92,
    name: "page-search___hr"
  }, {
    path: "/hr/prijava",
    component: _1c68a54b,
    name: "page-login___hr"
  }, {
    path: "/hr/profil",
    component: _6407006a,
    name: "page-profile___hr"
  }, {
    path: "/hr/redirection",
    component: _1e6b8955,
    name: "redirection___hr"
  }, {
    path: "/hr/registracija",
    component: _67e54bf7,
    name: "page-register___hr"
  }, {
    path: "/hr/sitemap.xml",
    component: _a1746a36,
    name: "sitemap.xml___hr"
  }, {
    path: "/hr/social-login",
    component: _56efaa18,
    name: "page-social-login___hr"
  }, {
    path: "/hr/zaboravljena-lozinka",
    component: _5d91a0c8,
    name: "page-forgot-password___hr"
  }, {
    path: "/hr/admin/cache",
    component: _1d740332,
    name: "admin-cache___hr"
  }, {
    path: "/hr/admin/consent",
    component: _d8cdfb62,
    name: "admin-consent___hr"
  }, {
    path: "/hr/admin/templates",
    component: _3ca95250,
    name: "admin-templates___hr"
  }, {
    path: "/hr/naplata/uspjesna",
    component: _3a4d5fd2,
    name: "page-checkout-success___hr"
  }, {
    path: "/hr/admin/consent/:slug",
    component: _2ec580b8,
    name: "admin-consent-slug___hr"
  }, {
    path: "/admin/consent/:slug",
    component: _2ec580b8,
    name: "admin-consent-slug___en"
  }, {
    path: "/hr/dogadjaj/:id",
    component: _9728ef0c,
    name: "page-event___hr"
  }, {
    path: "/hr/objave/:slug?",
    component: _4e557018,
    name: "page-blog___hr"
  }, {
    path: "/hr/product/:slug?",
    component: _78322ec5,
    name: "page-product___hr"
  }, {
    path: "/hr/shop/:slug?",
    component: _6a5fd778,
    name: "page-shop___hr"
  }, {
    path: "/blog/:slug?",
    component: _4e557018,
    name: "page-blog___en"
  }, {
    path: "/event/:id",
    component: _9728ef0c,
    name: "page-event___en"
  }, {
    path: "/hr/:slug?",
    component: _029bcd16,
    name: "page-post___hr"
  }, {
    path: "/product/:slug?",
    component: _78322ec5,
    name: "page-product___en"
  }, {
    path: "/shop/:slug?",
    component: _6a5fd778,
    name: "page-shop___en"
  }, {
    path: "/:slug?",
    component: _029bcd16,
    name: "page-post___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
