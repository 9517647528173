// import axios from 'axios'
export const state = () => ({
  conversionRate: 7.5345,
  cartItems: null,
})

export const mutations = {
  ADD_CART_ITEMS(state, items) {
    state.cartItems = items
  },
  CLEAR_STORE_CART(state) {
    state.cartItems = null
  },
}
export const actions = {
  addCartItems({ commit }, items) {
    commit('ADD_CART_ITEMS', items)
  },
  clearCartState({ commit }) {
    commit('CLEAR_STORE_CART')
  },
}
// export const actions = {
//   async nuxtServerInit({ commit }, { req, redirect }) {
//     if (process.browser) return
//     const ip =
//       (req.headers['x-forwarded-for'] || '').split(',').pop().trim() ||
//       req.connection.remoteAddress ||
//       req.socket.remoteAddress ||
//       req.connection.socket.remoteAddress
//     if (!ip) return
//     axios.defaults.baseURL = undefined
//     const prefix = req.headers.host.includes('localhost')
//       ? 'http://'
//       : 'https://'
//     const baseUrl = prefix + req.headers.host
//     try {
//       // eslint-disable-next-line
//       const response = await axios.request({
//         url: '/get-location-from-ip',
//         method: 'get',
//         baseURL: baseUrl,
//         params: {
//           ip,
//         },
//       })
//       // console.log(response.data)
//       let countryIsoCode = null
//       if (response && response.data) {
//         countryIsoCode = response.data
//         if (
//           countryIsoCode === 'CH'
//           //  || countryIsoCode === 'US' ||
//           // countryIsoCode === 'UM' ||
//           // countryIsoCode === 'CA'
//         ) {
//           return redirect({
//             path: '/redirection',
//             query: { country: countryIsoCode },
//           })
//         }
//       }
//     } catch (e) {
//       console.log(e)
//     }
//   },
// }
export const getters = {
  getConversionRate: (state) => {
    return state.conversionRate
  },
  cartObject: (state) => {
    return state.cartItems
  },
}
