

const asyncCartView = () => import('../../../components/cmsOverrides/CartView')
const asyncCheckoutSuccessView = () => import('../../../components/cmsOverrides/CheckoutSuccessView')
const asyncCheckoutView = () => import('../../../components/cmsOverrides/CheckoutView')
const asyncCookieElement = () => import('../../../components/cmsOverrides/CookieElement')
const asyncErrorView = () => import('../../../components/cmsOverrides/ErrorView')
const asyncFooterElement = () => import('../../../components/cmsOverrides/FooterElement')
const asyncForgotPasswordView = () => import('../../../components/cmsOverrides/ForgotPasswordView')
const asyncHeaderElement = () => import('../../../components/cmsOverrides/HeaderElement')
const asyncLoginView = () => import('../../../components/cmsOverrides/LoginView')
const asyncPostCategory = () => import('../../../components/cmsOverrides/PostCategory')
const asyncPostList = () => import('../../../components/cmsOverrides/PostList')
const asyncPostView = () => import('../../../components/cmsOverrides/PostView')
const asyncProfileView = () => import('../../../components/cmsOverrides/ProfileView')
const asyncRegisterView = () => import('../../../components/cmsOverrides/RegisterView')
import CartView from '../../../components/cmsOverrides/CartView'
import CheckoutSuccessView from '../../../components/cmsOverrides/CheckoutSuccessView'
import CheckoutView from '../../../components/cmsOverrides/CheckoutView'
import CookieElement from '../../../components/cmsOverrides/CookieElement'
import ErrorView from '../../../components/cmsOverrides/ErrorView'
import FooterElement from '../../../components/cmsOverrides/FooterElement'
import ForgotPasswordView from '../../../components/cmsOverrides/ForgotPasswordView'
import HeaderElement from '../../../components/cmsOverrides/HeaderElement'
import LoginView from '../../../components/cmsOverrides/LoginView'
import PostCategory from '../../../components/cmsOverrides/PostCategory'
import PostList from '../../../components/cmsOverrides/PostList'
import PostView from '../../../components/cmsOverrides/PostView'
import ProfileView from '../../../components/cmsOverrides/ProfileView'
import RegisterView from '../../../components/cmsOverrides/RegisterView'

const asyncCategoryList = () => import('./shop/CategoryList')
const asyncProductList = () => import('./shop/ProductList')
const asyncMaintenanceView = () => import('./shop/MaintenanceView')
const asyncSearchView = () => import('./shop/SearchView')
const asyncCartModalView = () => import('./shop/CartModalView')
const asyncSocialLoginView = () => import('./shop/SocialLoginView')
const asyncProductListAll = () => import('./shop/ProductListAll')
const asyncConfirmEmailView = () => import('./shop/ConfirmEmailView')
const asyncBundleCustomizationModalView = () => import('./shop/BundleCustomizationModalView')
import CategoryList from './shop/CategoryList'
import ProductList from './shop/ProductList'
import MaintenanceView from './shop/MaintenanceView'
import SearchView from './shop/SearchView'
import CartModalView from './shop/CartModalView'
import SocialLoginView from './shop/SocialLoginView'
import ProductListAll from './shop/ProductListAll'
import ConfirmEmailView from './shop/ConfirmEmailView'
import BundleCustomizationModalView from './shop/BundleCustomizationModalView'

export {asyncCartView, CartView, asyncCheckoutSuccessView, CheckoutSuccessView, asyncCheckoutView, CheckoutView, asyncCookieElement, CookieElement, asyncErrorView, ErrorView, asyncFooterElement, FooterElement, asyncForgotPasswordView, ForgotPasswordView, asyncHeaderElement, HeaderElement, asyncLoginView, LoginView, asyncPostCategory, PostCategory, asyncPostList, PostList, asyncPostView, PostView, asyncProfileView, ProfileView, asyncRegisterView, RegisterView, asyncCategoryList, CategoryList, asyncProductList, ProductList, asyncMaintenanceView, MaintenanceView, asyncSearchView, SearchView, asyncCartModalView, CartModalView, asyncSocialLoginView, SocialLoginView, asyncProductListAll, ProductListAll, asyncConfirmEmailView, ConfirmEmailView, asyncBundleCustomizationModalView, BundleCustomizationModalView}
