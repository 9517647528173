<template>
  <controller-cart
    v-slot="{ items, priceSummary, form, cartState, applyCoupon, removeCoupon }"
    class="form-box form-label"
  >
    <p class="mb-8 uppercase font-pragmatica-extended font-extrabold">
      {{ $t('hsc.cart.orderReview') }}
    </p>
    <p
      v-show="items.length"
      class="mt-10 flex items-center justify-between font-pragmatica-extended font-extrabold"
    >
      {{ items.length }}
      {{ items.length > 1 ? $t('hsc.cart.items') : $t('hsc.cart.item') }}
    </p>
    <hr class="mt-2 mb-4" />
    <div class="flex items-center justify-between">
      <p>{{ $t('hsc.cart.subtotal') }}</p>
      <p class="text-right pb-3">
        <span class="leading-1.15">
          {{ $currency(priceSummary.itemsTotal) }}
        </span>
        <span
          v-if="returnEuroPrice(priceSummary.itemsTotal)"
          class="text-ps font-bold text-right inline-block w-full"
        >
          ({{ returnEuroPrice(priceSummary.itemsTotal) }})
        </span>
      </p>
    </div>
    <div class="flex items-center justify-between">
      <p>{{ $t('hsc.cart.tax') }}</p>
      <p class="text-right pb-3">
        <span class="leading-1.15">
          {{
            priceSummary.tax.orderTotal
              ? $currency(priceSummary.tax.orderTotal)
              : $currency(priceSummary.tax.cartTotal)
          }}
        </span>
        <span
          v-if="returnEuroPrice(priceSummary.tax.cartTotal)"
          class="text-ps font-bold text-right inline-block w-full"
        >
          ({{ returnEuroPrice(priceSummary.tax.cartTotal) }})
        </span>
      </p>
    </div>
    <div class="flex items-center justify-between">
      <p>{{ $t('hsc.cart.shipping') }}</p>
      <p class="text-right pb-3">
        <span class="leading-1.15">
          {{ $currency(priceSummary.delivery) }}
        </span>
        <span
          v-if="returnEuroPrice(priceSummary.delivery)"
          class="text-ps font-bold text-right inline-block w-full"
        >
          ({{ returnEuroPrice(priceSummary.delivery) }})
        </span>
      </p>
    </div>

    <hr class="my-6" />
    <div
      class="flex items-center justify-between font-pragmatica-extended font-extrabold"
    >
      <p>{{ $t('hsc.cart.total') }}</p>
      <p class="text-right">
        <span class="leading-1.15">
          {{
            priceSummary.orderTotal
              ? $currency(priceSummary.orderTotal)
              : $currency(priceSummary.cartTotal)
          }}
        </span>
        <span
          v-if="returnEuroPrice(priceSummary.cartTotal)"
          class="text-ps font-bold text-right inline-block w-full"
        >
          ({{ returnEuroPrice(priceSummary.cartTotal) }})
        </span>
      </p>
    </div>

    <template v-if="!hideCode">
      <div class="mt-6 font-extrabold font-pragmatica-extended form-text">
        <custom-form
          v-if="!cartState.couponCode"
          v-model="form.coupon"
          :submit-method="applyCoupon"
          :placeholder="$t('hsc.form.inputs.discountCode')"
          :btn-text="$t('hsc.form.actions.applyCode')"
          grow-after-small-screen
          max-input-width-class="max-w-14.5rem"
          apply-borders
          rounded
        />
        <form
          v-else
          class="flex flex-col items-end justify-between"
          @submit.prevent="removeCoupon"
        >
          <div class="font-normal mb-4">
            {{ $t('hsc.cart.coupon') }} "{{ cartState.couponCode }}" -
            {{ $currency(priceSummary.coupon) }}
            <span
              v-if="returnEuroPrice(priceSummary.coupon)"
              class="text-ps font-bold"
            >
              ({{ returnEuroPrice(priceSummary.coupon) }})
            </span>
          </div>

          <button
            type="submit"
            class="px-4 w-full h-12 bg-darkGrayishViolet tracking-0.14rem uppercase text-white font-pragmatica-extended font-extrabold sm:h-16 sm:px-8 focus:outline-none"
          >
            {{ $t('hsc.cart.remove') + ' ' + $t('hsc.cart.coupon') }}
          </button>
        </form>
      </div>
    </template>
  </controller-cart>
</template>
<script>
import ControllerCart from 'Controllers/ControllerCart'
import CustomForm from '@/components/ui/forms/CustomForm'

export default {
  components: {
    ControllerCart,
    CustomForm,
  },
  props: {
    hideCode: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
