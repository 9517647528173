/* eslint-disable dot-notation */
import Vue from 'vue'
Vue.mixin({
  methods: {
    returnEuroPrice(price) {
      return false
      // eslint-disable-next-line no-unreachable
      const { code } = this.$i18n.localeProperties
      if (code && code === 'en') return null
      return this.$currency(
        price * this.$store.getters.getConversionRate
      ).replace('EUR', 'HRK')
    },
  },
})
