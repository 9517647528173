<template>
  <div
    id="footer"
    class="w-full bg-whiteSmoke py-16 text-lg lg:py-32 lg:text-1.4rem"
  >
    <div id="footer-container" class="content-container">
      <img
        alt="Logo"
        src="/images/hsc-logo.png"
        class="w-4.5rem h-20 mb-12 mx-auto lg:mb-20 lg:mx-0"
      />

      <div
        id="footer-main-content"
        class="flex flex-wrap text-center justify-between mb-16 lg:mb-7rem lg:text-left"
      >
        <controller-footer
          v-slot="{ item }"
          class="flex flex-col w-1/2 sm:w-1/3 lg:w-auto"
        >
          <cms-link :to="item">
            {{ item.title }}
          </cms-link>
        </controller-footer>

        <controller-footer
          v-slot="{ item }"
          footer-number="2"
          class="flex flex-col w-1/2 sm:w-1/3 lg:w-auto"
        >
          <cms-link :to="item">
            {{ item.title }}
          </cms-link>
        </controller-footer>

        <controller-footer
          v-slot="{ item }"
          footer-number="3"
          class="flex flex-col my-4 w-full sm:w-1/3 sm:mb-0 sm:my-0 lg:w-auto lg:mb-4"
        >
          <cms-link :to="item">
            {{ item.title }}
          </cms-link>
        </controller-footer>

        <div class="w-full mt-8 lg:w-auto lg:mt-0 lg:mx-0">
          <div class="mb-8">
            <span class="footer-title">{{ $t('hsc.footer.newsletter') }}</span>
            <controller-newsletter
              v-slot="{ form, submit, generateErrorMessage }"
              class="mt-4"
            >
              <custom-form
                v-model="form.email"
                :submit-method="submit"
                :placeholder="`${$t('hsc.form.inputs.emailAddress')}`"
                :btn-text="$t('hsc.form.actions.subscribe')"
                :error-message="generateErrorMessage('form.email')"
              />
              <p
                v-if="generateErrorMessage('form.email')"
                class="text-red-700 text-base leading-tight mt-2"
              >
                {{ generateErrorMessage('form.email') }}
              </p>
            </controller-newsletter>
          </div>
          <div class="w-full">
            <span class="footer-title">{{ $t('hsc.footer.community') }}</span>
            <div
              class="mt-4 pb-6 flex items-center justify-center lg:justify-start gap-8"
            >
              <a
                title="Facebook"
                href="https://www.facebook.com/hsc.5g.life"
                target="_blank"
              >
                <font-awesome-icon
                  class="cursor-pointer"
                  size="xl"
                  icon="fa-brands fa-facebook fa-xl"
                />
              </a>
              <a
                title="Instagram"
                href="https://www.instagram.com/hsc.life/"
                target="_blank"
              >
                <font-awesome-icon
                  class="cursor-pointer"
                  size="xl"
                  icon="fa-brands fa-instagram fa-xl"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCsXbgtzhstPmdG2cvUp-kBg"
                target="_blank"
                title="Youtube"
              >
                <font-awesome-icon
                  class="cursor-pointer"
                  size="xl"
                  icon="fa-brands fa-youtube"
                />
              </a>
              <a
                href="https://www.tiktok.com/@hsc.life"
                target="_blank"
                title="TikTok"
              >
                <font-awesome-icon
                  class="cursor-pointer"
                  size="xl"
                  icon="fa-brands fa-tiktok"
                />
              </a>
              <a
                href="https://twitter.com/hsc_5g_life"
                target="_blank"
                title="Twitter"
              >
                <font-awesome-icon
                  class="cursor-pointer"
                  size="xl"
                  icon="fa-brands fa-twitter"
                />
              </a>
            </div>
            <span class="footer-title">{{ $t('hsc.footer.contact') }}</span>
            <div
              class="mt-4 flex items-center justify-center lg:justify-start gap-8"
            >
              <a
                href="viber://contact?number=%2B385913584122"
                target="_blank"
                title="Viber"
              >
                <font-awesome-icon
                  class="cursor-pointer"
                  size="xl"
                  icon="fa-brands fa-viber"
                />
              </a>
              <a
                href="https://wa.me/385913584122"
                target="_blank"
                title="Whatsapp"
              >
                <font-awesome-icon
                  class="cursor-pointer"
                  size="xl"
                  icon="fa-brands fa-whatsapp"
                />
              </a>
              <a
                href="https://t.me/+385913584122"
                target="_blank"
                title="Telegram"
              >
                <font-awesome-icon
                  class="cursor-pointer"
                  size="xl"
                  icon="fa-brands fa-telegram"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="footer-signature">
        <span class="font-pragmatica-extended font-bold tracking-0.14rem">
          TSP d.o.o
        </span>
        <hr class="border-veryDarkGray my-3 sm:my-6" />
        <div class="flex justify-between">
          <div
            class="font-pragmatica-extended font-bold tracking-0.14rem text-sm sm:text-1.4rem"
          >
            HSC
            <span class="font-pragmatica font-normal tracking-normal"
              >&copy; {{ $t('hsc.footer.rights') }}</span
            >
          </div>
          <controller-footer
            v-slot="{ item }"
            footer-number="4"
            class="grid grid-rows-2 gap-2 sm:grid-cols-2 sm:grid-rows-none sm:gap-6"
          >
            <cms-link :to="item" class="hover:underline">
              {{ item.title }}
            </cms-link>
          </controller-footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ControllerFooter from 'Controllers/ControllerFooter'
import ControllerNewsletter from 'Controllers/ControllerNewsletter'
import CustomForm from '@/components/ui/forms/CustomForm'
import FaIcons from '@/mixins/FaIcons'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
  faFacebook,
  faInstagram,
  faYoutube,
  faTiktok,
  faTwitter,
  faWhatsapp,
  faTelegram,
  faViber,
} from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(
  faInstagram,
  faFacebook,
  faYoutube,
  faTiktok,
  faTwitter,
  faWhatsapp,
  faTelegram,
  faViber
)
// import PopUp from '@/components/ui/modals/PopUp.vue'
export default {
  components: {
    // PopUp,
    ControllerFooter,
    ControllerNewsletter,
    CustomForm,
    FontAwesomeIcon,
  },
  mixins: [FaIcons],
}
</script>
<style scoped lang="scss">
#footer #footer-container #footer-main-content div a:first-child,
.footer-title {
  @apply font-pragmatica-extended tracking-0.14rem uppercase font-bold cursor-default;
}
.social-icon {
  @apply text-2rem cursor-pointer;
}
.social-icon:hover {
  @apply text-pureYellow;
}
#footer #footer-container #footer-main-content div a:not(:first-child):hover {
  @apply underline;
}
svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa {
  &:hover {
    path {
      fill: #efe400 !important;
    }
  }
}
</style>
