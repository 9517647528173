var render = function render(){var _vm=this,_c=_vm._self._c;return _c('controller-post',{scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{staticClass:"relative box-border flex flex-col min-h-100vh-mobile-menu md:min-h-100vh-menu"},[_c('cms-image',{staticClass:"absolute top-0 left-0 w-full h-full object-cover object-center",attrs:{"media":item.media}}),_vm._v(" "),_c('div',{staticClass:"relative flex-grow bg-darkGrayishViolet-55 w-full flex items-center justify-center box-content"},[_c('div',{staticClass:"content-container text-white text-center font-pragmatica-extended my-20"},[_c('div',{staticClass:"text-lg md:text-1.6rem"},[_c('p',[_vm._v(_vm._s(_vm.getCategoryString(item.terms.categories)))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.getDate(item.published_at)))])]),_vm._v(" "),_c('h1',{staticClass:"main-title overflow-hidden leading-tight my-10"},[_vm._v("\n          "+_vm._s(item.title)+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"text-xl font-extrabold md:text-2rem"},[_vm._v("\n          "+_vm._s(item.post_author)+"\n        ")]),_vm._v(" "),_c('button',{staticClass:"mt-8 font-pragmatica inline-flex items-center focus:outline-none",on:{"click":_vm.toggleSocialShare}},[_c('span',{staticClass:"text-1.4rem"},[_vm._v(_vm._s(_vm.$t('hsc.post.share')))]),_c('fa',{staticClass:"ml-3 text-2xl transitioned",attrs:{"icon":_vm.icons.basic.share}})],1)])]),_vm._v(" "),_c('div',{staticClass:"absolute left-0 w-full bottom-5% flex items-center justify-center"},[_c('fa',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
          el: '#post-container',
          duration: 300,
          easing: 'ease',
          offset: -110,
          cancelable: true,
          x: false,
          y: true,
        }),expression:"{\n          el: '#post-container',\n          duration: 300,\n          easing: 'ease',\n          offset: -110,\n          cancelable: true,\n          x: false,\n          y: true,\n        }"}],staticClass:"block text-3xl text-white cursor-pointer bouncing-arrow",attrs:{"id":"down-arrow-icon","icon":_vm.icons.basic.downArrow}})],1)],1),_vm._v(" "),_c('div',{staticClass:"w-5/6 mx-auto pt-16 pb-24 md:pt-24 md:pb-48 lg:w-73rem",attrs:{"id":"post-container"}},[_c('div',{attrs:{"id":"post-content"},domProps:{"innerHTML":_vm._s(item.body)}}),_vm._v(" "),_c('hr',{staticClass:"my-12 border-lightGrayishBlue"}),_vm._v(" "),_c('div',{staticClass:"flex items-center justify-between text-1.4rem"},[_c('cms-link',{staticClass:"font-bold mr-10 flex-shrink-0 transitioned hover:text-pureYellow",attrs:{"to":_vm.$Page.Blog}},[_vm._v(_vm._s(_vm.$t('hsc.post.backToBlog')))]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.terms.tags.length),expression:"item.terms.tags.length"}],staticClass:"flex flex-wrap items-center justify-end"},[_c('span',{staticClass:"text-pureYellow"},[_vm._v("Tags:")]),_vm._v(" "),_vm._l((item.terms.tags),function(tag){return _c('span',{key:tag.id,staticClass:"ml-2 md:ml-10"},[_vm._v("\n          "+_vm._s(tag.title)+"\n        ")])})],2)],1)]),_vm._v(" "),_c('transition',{attrs:{"name":"fade-200ms"}},[_c('client-only',[(_vm.shareModalActive)?_c('social-share-modal',{attrs:{"endpoint":item.slug},on:{"closeSocialShare":_vm.closeSocialShare}}):_vm._e()],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }