<template>
  <controller-post v-slot="{ item }">
    <div
      class="relative box-border flex flex-col min-h-100vh-mobile-menu md:min-h-100vh-menu"
    >
      <cms-image
        :media="item.media"
        class="absolute top-0 left-0 w-full h-full object-cover object-center"
      />
      <div
        class="relative flex-grow bg-darkGrayishViolet-55 w-full flex items-center justify-center box-content"
      >
        <div
          class="content-container text-white text-center font-pragmatica-extended my-20"
        >
          <div class="text-lg md:text-1.6rem">
            <p>{{ getCategoryString(item.terms.categories) }}</p>
            <p>{{ getDate(item.published_at) }}</p>
          </div>

          <h1 class="main-title overflow-hidden leading-tight my-10">
            {{ item.title }}
          </h1>
          <p class="text-xl font-extrabold md:text-2rem">
            {{ item.post_author }}
          </p>
          <button
            class="mt-8 font-pragmatica inline-flex items-center focus:outline-none"
            @click="toggleSocialShare"
          >
            <span class="text-1.4rem">{{ $t('hsc.post.share') }}</span
            ><fa :icon="icons.basic.share" class="ml-3 text-2xl transitioned" />
          </button>
        </div>
      </div>
      <div
        class="absolute left-0 w-full bottom-5% flex items-center justify-center"
      >
        <fa
          id="down-arrow-icon"
          v-scroll-to="{
            el: '#post-container',
            duration: 300,
            easing: 'ease',
            offset: -110,
            cancelable: true,
            x: false,
            y: true,
          }"
          :icon="icons.basic.downArrow"
          class="block text-3xl text-white cursor-pointer bouncing-arrow"
        />
      </div>
    </div>
    <div
      id="post-container"
      class="w-5/6 mx-auto pt-16 pb-24 md:pt-24 md:pb-48 lg:w-73rem"
    >
      <div id="post-content" v-html="item.body" />
      <hr class="my-12 border-lightGrayishBlue" />
      <div class="flex items-center justify-between text-1.4rem">
        <cms-link
          :to="$Page.Blog"
          class="font-bold mr-10 flex-shrink-0 transitioned hover:text-pureYellow"
          >{{ $t('hsc.post.backToBlog') }}</cms-link
        >
        <div
          v-show="item.terms.tags.length"
          class="flex flex-wrap items-center justify-end"
        >
          <span class="text-pureYellow">Tags:</span>
          <span
            v-for="tag in item.terms.tags"
            :key="tag.id"
            class="ml-2 md:ml-10"
          >
            {{ tag.title }}
          </span>
        </div>
      </div>
    </div>
    <transition name="fade-200ms">
      <client-only>
        <social-share-modal
          v-if="shareModalActive"
          :endpoint="item.slug"
          @closeSocialShare="closeSocialShare"
        />
      </client-only>
    </transition>
  </controller-post>
</template>
<script>
import ControllerPost from 'Controllers/ControllerPost'
import FaIcons from '@/mixins/FaIcons'
import DateFormatter from '@/mixins/DateFormatter'
import SocialShareModal from '@/components/ui/social/SocialShareModal'
export default {
  components: {
    ControllerPost,
    SocialShareModal,
  },
  mixins: [FaIcons, DateFormatter],
  data() {
    return {
      shareModalActive: false,
    }
  },
  mounted() {
    this.$nuxt.$on('custom-scroll', this.handleScroll)
  },
  beforeDestroy() {
    this.$nuxt.$off('custom-scroll')
  },

  methods: {
    getCategoryString(categories) {
      let stringBuilder = ''
      categories.forEach((category, index) => {
        stringBuilder += category.title
        if (index <= categories.length - 3) {
          stringBuilder += ', '
        } else if (index === categories.length - 2) {
          stringBuilder += ' & '
        }
      })
      return stringBuilder
    },
    toggleSocialShare() {
      this.shareModalActive = !this.shareModalActive
    },
    closeSocialShare() {
      this.shareModalActive = false
    },
    handleScroll() {
      // If browser does not support pageYOffset
      if (!window.pageYOffset && window.pageYOffset !== 0) return
      const arrowIcon = document.getElementById('down-arrow-icon')
      if (window.pageYOffset < window.innerHeight / 1.75) {
        if (!arrowIcon.classList.contains('bouncing-arrow')) {
          arrowIcon.classList.add('bouncing-arrow')
        }
      } else {
        arrowIcon.classList.remove('bouncing-arrow')
      }
    },
  },
}
</script>
<style scoped>
button:hover svg {
  @apply text-pureYellow transform scale-125;
}

/* todo: figure out how to fix this, if there are span children inside elements,
     !important wont override its style so i have to do it like this */

::v-deep #post-content ul,
ol {
  @apply pl-10;
}

::v-deep #post-content ul li {
  @apply list-disc;
}

::v-deep #post-content ol li {
  @apply list-decimal;
}

::v-deep #post-content h1,
::v-deep #post-content h1 span {
  @apply font-pragmatica-extended font-bold text-2xl !important;
}
::v-deep #post-content h2,
::v-deep #post-content h2 span,
::v-deep #post-content h3,
::v-deep #post-content h3 span,
::v-deep #post-content h4,
::v-deep #post-content h4 span,
::v-deep #post-content h5,
::v-deep #post-content h5 span,
::v-deep #post-content h6,
::v-deep #post-content h6 span {
  @apply font-pragmatica-extended font-bold text-xl !important;
}

::v-deep #post-content p,
::v-deep #post-content p span,
::v-deep #post-content li,
::v-deep #post-content li span,
::v-deep #post-content pre {
  @apply text-xl !important;
}

::v-deep #post-content a {
  @apply text-xl text-blue-600 !important;
}

::v-deep #post-content a:hover {
  @apply underline !important;
}

::v-deep #post-content video {
  @apply w-full h-20rem !important;
}

@media only screen and (min-width: 640px) {
  ::v-deep #post-content video {
    @apply h-32rem !important;
  }
}

@media only screen and (min-width: 768px) {
  ::v-deep #post-content h1 {
    @apply text-2rem !important;
  }
  ::v-deep #post-content h2,
  ::v-deep #post-content h2 span,
  ::v-deep #post-content h3,
  ::v-deep #post-content h3 span,
  ::v-deep #post-content h4,
  ::v-deep #post-content h4 span,
  ::v-deep #post-content h5,
  ::v-deep #post-content h5 span,
  ::v-deep #post-content h6,
  ::v-deep #post-content h6 span,
  ::v-deep #post-content p,
  ::v-deep #post-content p span,
  ::v-deep #post-content li,
  ::v-deep #post-content li span,
  ::v-deep #post-content pre,
  ::v-deep #post-content a {
    @apply text-1.6rem !important;
  }
}

@media only screen and (min-width: 1024px) {
  ::v-deep #post-content video {
    @apply h-40rem !important;
  }
}

@keyframes bouncing {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}
.bouncing-arrow {
  animation-name: bouncing;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
}
</style>
