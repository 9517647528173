<template>
  <div
    class="overflow-hidden py-8 min-h-100vh-mobile-menu md:min-h-100vh-menu md:pt-40 md:pb-48"
  >
    <div class="content-container">
      <p
        class="main-title mb-12 uppercase overflow-hidden font-extrabold leading-tight font-pragmatica-extended lg:mb-20"
      >
        {{ $t('hsc.profile.personalData') }}
      </p>
      <div v-click-outside="onClickOutside">
        <div class="overflow-hidden">
          <div>
            <div
              class="pb-4 dropdown-activator md:pb-8"
              @click="toggleClickedItem(profileItems.profileSettings)"
            >
              <p class="dropdown-activator-label">
                {{ $t('hsc.profile.personalData') }}
              </p>
              <button
                class="dropdown-activator-icon transitioned transform"
                :class="
                  clickedItem === profileItems.profileSettings
                    ? '-rotate-90'
                    : ''
                "
              >
                <fa :icon="icons.basic.rightArrow" />
              </button>
            </div>
            <hr />

            <div v-if="clickedItem === profileItems.profileSettings">
              <div
                class="form-box"
                :class="{
                  'my-10 md:mb-4.5rem':
                    clickedItem === profileItems.profileSettings,
                }"
              >
                <controller-address
                  v-slot="{
                    form,
                    options,
                    allowedCountries,
                    submit,
                    isStepActive,
                    generateErrorMessage,
                  }"
                  @submitted="showSuccessToast"
                >
                  <form @submit.prevent="submit">
                    <fieldset
                      :class="{
                        'mb-16 lg:mb-24': options.useDifferentBillingAddress,
                      }"
                    >
                      <p
                        class="form-label mb-1.8rem uppercase font-pragmatica-extended font-extrabold"
                      >
                        {{ $t('hsc.checkout.shippingAddress') }}
                      </p>
                      <div
                        class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:gap-6"
                      >
                        <section>
                          <label class="form-label">
                            {{ $t('hsc.form.inputs.firstName') }}*
                            <input
                              v-model="form.deliveryForm.firstName"
                              type="text"
                              class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                              :placeholder="$t('hsc.form.inputs.firstName')"
                            />
                          </label>
                          <p
                            v-if="
                              generateErrorMessage(
                                'form.deliveryForm.firstName'
                              )
                            "
                            class="error-msg"
                          >
                            {{
                              generateErrorMessage(
                                'form.deliveryForm.firstName'
                              )
                            }}
                          </p>
                        </section>
                        <section>
                          <label class="form-label">
                            {{ $t('hsc.form.inputs.lastName') }}*
                            <input
                              v-model.trim="form.deliveryForm.lastName"
                              type="text"
                              class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                              :placeholder="$t('hsc.form.inputs.lastName')"
                            />
                          </label>
                          <p
                            v-if="
                              generateErrorMessage('form.deliveryForm.lastName')
                            "
                            class="error-msg"
                          >
                            {{
                              generateErrorMessage('form.deliveryForm.lastName')
                            }}
                          </p>
                        </section>
                        <section>
                          <label class="form-label">
                            {{ $t('hsc.form.inputs.email') }}*
                            <input
                              v-model.trim="form.deliveryForm.contactEmail"
                              type="email"
                              class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                              :placeholder="$t('hsc.form.inputs.email')"
                            />
                          </label>
                          <p
                            v-if="
                              generateErrorMessage(
                                'form.deliveryForm.contactEmail'
                              )
                            "
                            class="error-msg"
                          >
                            {{
                              generateErrorMessage('deliveryForm.contactEmail')
                            }}
                          </p>
                        </section>
                        <section>
                          <label class="form-label">
                            {{ $t('hsc.form.inputs.phone') }}*
                            <input
                              v-model.trim="form.deliveryForm.contactPhone"
                              type="tel"
                              class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                              :placeholder="$t('hsc.form.inputs.phone')"
                            />
                          </label>
                          <p
                            v-if="
                              generateErrorMessage(
                                'form.deliveryForm.contactPhone'
                              )
                            "
                            class="error-msg"
                          >
                            {{
                              generateErrorMessage(
                                'form.deliveryForm.contactPhone'
                              )
                            }}
                          </p>
                        </section>
                        <section>
                          <label class="form-label">
                            {{ $t('hsc.form.inputs.street') }}*
                            <input
                              v-model.trim="form.deliveryForm.street"
                              type="text"
                              class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                              :placeholder="$t('hsc.form.inputs.street')"
                            />
                          </label>
                          <p
                            v-if="
                              generateErrorMessage('form.deliveryForm.street')
                            "
                            class="error-msg"
                          >
                            {{
                              generateErrorMessage('form.deliveryForm.street')
                            }}
                          </p>
                        </section>
                        <section>
                          <label class="form-label">
                            {{ $t('hsc.form.inputs.houseNumber') }}*
                            <input
                              v-model.trim="form.deliveryForm.houseNumber"
                              type="text"
                              class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                              :placeholder="$t('hsc.form.inputs.houseNumber')"
                            />
                          </label>
                          <p
                            v-if="
                              generateErrorMessage(
                                'form.deliveryForm.houseNumber'
                              )
                            "
                            class="error-msg"
                          >
                            {{
                              generateErrorMessage(
                                'form.deliveryForm.houseNumber'
                              )
                            }}
                          </p>
                        </section>
                        <section>
                          <label class="form-label">
                            {{ $t('hsc.form.inputs.country') }}*
                            <select
                              v-model="form.deliveryForm.country"
                              class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                              :placeholder="$t('hsc.form.inputs.country')"
                            >
                              <option value="">Please pick a country</option>
                              <option
                                v-for="country in allowedCountries"
                                :key="country.iso"
                                :value="country.iso"
                              >
                                {{ country.name }}
                              </option>
                            </select>
                          </label>
                          <p
                            v-if="
                              generateErrorMessage('form.deliveryForm.country')
                            "
                            class="error-msg"
                          >
                            {{
                              generateErrorMessage('form.deliveryForm.country')
                            }}
                          </p>
                        </section>
                        <section>
                          <label class="form-label">
                            {{ $t('hsc.form.inputs.city') }}*
                            <input
                              v-model.trim="form.deliveryForm.city"
                              type="text"
                              class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                              :placeholder="$t('hsc.form.inputs.city')"
                            />
                          </label>
                          <p
                            v-if="
                              generateErrorMessage('form.deliveryForm.city')
                            "
                            class="error-msg"
                          >
                            {{ generateErrorMessage('form.deliveryForm.city') }}
                          </p>
                        </section>
                        <section>
                          <label class="form-label">
                            {{ $t('hsc.form.inputs.postalCode') }}*
                            <input
                              v-model.trim="form.deliveryForm.zipCode"
                              type="text"
                              class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                              :placeholder="$t('hsc.form.inputs.postalCode')"
                            />
                          </label>
                          <p
                            v-if="
                              generateErrorMessage('form.deliveryForm.zipCode')
                            "
                            class="error-msg"
                          >
                            {{
                              generateErrorMessage('form.deliveryForm.zipCode')
                            }}
                          </p>
                        </section>
                        <section
                          class="flex items-end leading-none text-xl md:text-1.6rem"
                        >
                          <div class="mt-5">
                            <label class="flex items-center mb-5">
                              <input
                                v-model="options.useDifferentBillingAddress"
                                type="radio"
                                :value="false"
                              />
                              <span class="ml-2">{{
                                $t('hsc.checkout.useSameBillingAddress')
                              }}</span>
                            </label>
                            <label class="flex items-center">
                              <input
                                v-model="options.useDifferentBillingAddress"
                                type="radio"
                                :value="true"
                              />
                              <span class="ml-2">{{
                                $t('hsc.checkout.useDifferentBillingAddress')
                              }}</span>
                            </label>
                          </div>
                        </section>
                      </div>
                      <section
                        v-show="!options.useDifferentBillingAddress"
                        class="mt-12"
                      >
                        <multiple-bordered-button
                          primary-color-name="pureYellow"
                          text-color-name="darkGrayishViolet"
                          is-submit
                        >
                          <span class="btn-text">{{
                            $t('hsc.form.actions.save')
                          }}</span>
                        </multiple-bordered-button>
                      </section>
                    </fieldset>

                    <fieldset v-if="options.useDifferentBillingAddress">
                      <p
                        class="form-label mb-1.8rem uppercase font-pragmatica-extended font-extrabold"
                      >
                        {{ $t('hsc.checkout.billingAddress') }}
                      </p>
                      <div
                        class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:gap-6"
                      >
                        <section>
                          <label class="form-label">
                            {{ $t('hsc.form.inputs.firstName') }}*
                            <input
                              v-model="form.billingForm.firstName"
                              type="text"
                              class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                              :placeholder="$t('hsc.form.inputs.firstName')"
                            />
                          </label>
                          <p
                            v-if="
                              generateErrorMessage('form.billingForm.firstName')
                            "
                            class="error-msg"
                          >
                            {{
                              generateErrorMessage(
                                'form.billingForm.firstName'
                              )
                            }}*
                          </p>
                        </section>
                        <section>
                          <label class="form-label">
                            {{ $t('hsc.form.inputs.lastName') }}*
                            <input
                              v-model.trim="form.billingForm.lastName"
                              type="text"
                              class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                              :placeholder="$t('hsc.form.inputs.lastName')"
                            />
                          </label>
                          <p
                            v-if="
                              generateErrorMessage('form.billingForm.lastName')
                            "
                            class="error-msg"
                          >
                            {{
                              generateErrorMessage('form.billingForm.lastName')
                            }}
                          </p>
                        </section>
                        <section>
                          <label class="form-label">
                            {{ $t('hsc.form.inputs.email') }}*
                            <input
                              v-model.trim="form.billingForm.contactEmail"
                              type="email"
                              class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                              :placeholder="$t('hsc.form.inputs.email')"
                            />
                          </label>
                          <p
                            v-if="
                              generateErrorMessage(
                                'form.billingForm.contactEmail'
                              )
                            "
                            class="error-msg"
                          >
                            {{
                              generateErrorMessage(
                                'form.billingForm.contactEmail'
                              )
                            }}
                          </p>
                        </section>
                        <section>
                          <label class="form-label">
                            {{ $t('hsc.form.inputs.phone') }}*
                            <input
                              v-model.trim="form.billingForm.contactPhone"
                              type="tel"
                              class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                              :placeholder="$t('hsc.form.inputs.phone')"
                            />
                          </label>
                          <p
                            v-if="
                              generateErrorMessage(
                                'form.billingForm.contactPhone'
                              )
                            "
                            class="error-msg"
                          >
                            {{
                              generateErrorMessage(
                                'form.billingForm.contactPhone'
                              )
                            }}
                          </p>
                        </section>
                        <section>
                          <label class="form-label">
                            {{ $t('hsc.form.inputs.street') }}*
                            <input
                              v-model.trim="form.billingForm.street"
                              type="text"
                              class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                              :placeholder="$t('hsc.form.inputs.street')"
                            />
                          </label>
                          <p
                            v-if="generateErrorMessage('billingForm.street')"
                            class="error-msg"
                          >
                            {{ generateErrorMessage('billingForm.street') }}
                          </p>
                        </section>
                        <section>
                          <label class="form-label">
                            {{ $t('hsc.form.inputs.houseNumber') }}*
                            <input
                              v-model.trim="form.billingForm.houseNumber"
                              type="text"
                              class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                              :placeholder="$t('hsc.form.inputs.houseNumber')"
                            />
                          </label>
                          <p
                            v-if="
                              generateErrorMessage(
                                'form.billingForm.houseNumber'
                              )
                            "
                            class="error-msg"
                          >
                            {{
                              generateErrorMessage(
                                'form.billingForm.houseNumber'
                              )
                            }}
                          </p>
                        </section>
                        <section>
                          <label class="form-label">
                            {{ $t('hsc.form.inputs.country') }}*
                            <select
                              v-model="form.billingForm.country"
                              class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                              :placeholder="$t('hsc.form.inputs.country')"
                            >
                              <option value="">Please pick a country</option>
                              <option
                                v-for="country in allowedCountries"
                                :key="country.iso"
                                :value="country.iso"
                              >
                                {{ country.name }}
                              </option>
                            </select>
                          </label>
                          <p
                            v-if="
                              generateErrorMessage('form.billingForm.country')
                            "
                            class="error-msg"
                          >
                            {{
                              generateErrorMessage('form.billingForm.country')
                            }}
                          </p>
                        </section>
                        <section>
                          <label class="form-label">
                            {{ $t('hsc.form.inputs.city') }}*
                            <input
                              v-model.trim="form.billingForm.city"
                              type="text"
                              class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                              :placeholder="$t('hsc.form.inputs.city')"
                            />
                          </label>
                          <p
                            v-if="generateErrorMessage('form.billingForm.city')"
                            class="error-msg"
                          >
                            {{ generateErrorMessage('form.billingForm.city') }}
                          </p>
                        </section>
                        <section>
                          <label class="form-label">
                            {{ $t('hsc.form.inputs.postalCode') }}*
                            <input
                              v-model.trim="form.billingForm.zipCode"
                              type="text"
                              class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                              :placeholder="$t('hsc.form.inputs.postalCode')"
                            />
                          </label>
                          <p
                            v-if="
                              generateErrorMessage('form.billingForm.zipCode')
                            "
                            class="error-msg"
                          >
                            {{
                              generateErrorMessage('form.billingForm.zipCode')
                            }}
                          </p>
                        </section>
                      </div>

                      <section
                        v-show="options.useDifferentBillingAddress"
                        class="mt-12"
                      >
                        <multiple-bordered-button
                          primary-color-name="pureYellow"
                          text-color-name="darkGrayishViolet"
                          is-submit
                        >
                          <span class="btn-text">{{
                            $t('hsc.form.actions.save')
                          }}</span>
                        </multiple-bordered-button>
                      </section>
                    </fieldset>
                  </form>
                </controller-address>
              </div>
              <hr />
            </div>
          </div>
        </div>
        <div class="overflow-hidden">
          <div>
            <div
              class="py-4 dropdown-activator md:py-8"
              @click="toggleClickedItem(profileItems.orderHistory)"
            >
              <p class="dropdown-activator-label">
                {{ $t('hsc.profile.orderHistory') }}
              </p>
              <button
                class="dropdown-activator-icon transitioned transform"
                :class="
                  clickedItem === profileItems.orderHistory ? '-rotate-90' : ''
                "
              >
                <fa :icon="icons.basic.rightArrow" />
              </button>
            </div>
            <hr />

            <div v-show="clickedItem === profileItems.orderHistory">
              <controller-order-history
                v-slot="{ items, paginationState, loadMore, status }"
                class="form-box"
                :class="{
                  'my-10 md:mb-4.5rem':
                    clickedItem === profileItems.orderHistory,
                }"
              >
                <div v-if="items.length">
                  <table class="w-full">
                    <thead
                      class="font-bold border-b border-darkGrayishViolet text-base sm:text-1.4rem md:text-1.6rem"
                    >
                      <tr>
                        <td class="table-cell">
                          {{ $t('hsc.profile.orderNumber') }}
                        </td>
                        <td>
                          {{ $t('hsc.profile.date') }}
                        </td>
                        <td class="text-right md:text-left">
                          {{ $t('hsc.cart.total') }}
                        </td>
                        <td class="hidden text-right md:table-cell">
                          {{ $t('hsc.profile.orderStatus') }}
                        </td>
                      </tr>
                    </thead>
                    <tbody class="text-sm sm:text-1.4rem md:text-1.6rem">
                      <tr
                        v-for="item in items"
                        :key="item.id"
                        class="border-b border-lightGrayishBlue"
                      >
                        <td>{{ item.number }}</td>
                        <td>{{ getDate(item.issuedOn) }}</td>
                        <td class="text-right md:text-left">
                          {{ $currency(item.details.grandTotal) }} <br />
                          <span
                            v-if="returnEuroPrice(item.details.grandTotal)"
                            class="text-ps font-bold"
                          >
                            ({{ returnEuroPrice(item.details.grandTotal) }})
                          </span>
                        </td>
                        <td class="hidden text-right md:table-cell">
                          {{ item.label.name }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <multiple-bordered-button
                    v-show="!paginationState.onLastPage"
                    primary-color-name="pureYellow"
                    text-color-name="darkGrayishViolet"
                    class="mt-12"
                    :disabled="status.pending"
                    @clicked="loadMore"
                  >
                    <span class="btn-text">{{
                      $t('hsc.form.actions.loadMore')
                    }}</span>
                  </multiple-bordered-button>
                </div>
                <p
                  v-else
                  class="text-lg font-semibold uppercase font-pragmatica-extended md:text-2xl text-center"
                >
                  {{ $t('hsc.profile.emptyOrderHistory') }}
                </p>
                <div></div>
              </controller-order-history>
              <hr />
            </div>
          </div>
        </div>
        <div class="overflow-hidden">
          <div>
            <div
              class="py-4 dropdown-activator md:py-8"
              @click="toggleClickedItem(profileItems.passwordSettings)"
            >
              <p class="dropdown-activator-label">
                {{ $t('hsc.profile.passwordSettings') }}
              </p>
              <button
                class="dropdown-activator-icon transitioned transform"
                :class="
                  clickedItem === profileItems.passwordSettings
                    ? '-rotate-90'
                    : ''
                "
              >
                <fa :icon="icons.basic.rightArrow" />
              </button>
            </div>
            <hr />

            <div v-show="clickedItem === profileItems.passwordSettings">
              <controller-change-password
                v-slot="{ form, submit, status, generateErrorMessage }"
                class="form-box"
                :class="{
                  'my-10 md:mb-4.5rem':
                    clickedItem === profileItems.passwordSettings,
                }"
                @submitted="handlePasswordChangeSuccess"
              >
                <form @submit.prevent="submit">
                  <p
                    class="form-label mb-1.8rem uppercase font-pragmatica-extended font-extrabold"
                  >
                    {{ $t('hsc.profile.passwordSettings') }}
                  </p>
                  <div class="grid grid-cols-1 gap-5 lg:grid-cols-3 lg:gap-6">
                    <section>
                      <label class="form-label">
                        {{ $t('hsc.form.inputs.oldPassword') }}*
                        <div class="relative">
                          <input
                            v-model.trim="form.oldPassword"
                            :type="
                              passwordOptions.oldPasswordVisible
                                ? 'text'
                                : 'password'
                            "
                            class="form-text password-input-padding form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.oldPassword')"
                          />
                          <fa
                            :icon="
                              passwordOptions.oldPasswordVisible
                                ? `${icons.basic.eyeSlash}`
                                : `${icons.basic.eye}`
                            "
                            class="password-eye-icon transitioned"
                            @click.stop="toggleOldPasswordVisibility"
                          />
                        </div>
                      </label>
                      <p
                        v-if="generateErrorMessage('form.oldPassword')"
                        class="error-msg"
                      >
                        {{ generateErrorMessage('form.oldPassword') }}
                      </p>
                    </section>
                    <section>
                      <label class="form-label">
                        {{ $t('hsc.form.inputs.newPassword') }}*
                        <div class="relative">
                          <input
                            v-model.trim="form.newPassword"
                            :type="
                              passwordOptions.newPasswordVisible
                                ? 'text'
                                : 'password'
                            "
                            class="form-text password-input-padding form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.newPassword')"
                          />
                          <fa
                            :icon="
                              passwordOptions.newPasswordVisible
                                ? `${icons.basic.eyeSlash}`
                                : `${icons.basic.eye}`
                            "
                            class="password-eye-icon transitioned"
                            @click.stop="toggleNewPasswordVisibility"
                          />
                        </div>
                      </label>
                      <p
                        v-if="generateErrorMessage('form.newPassword')"
                        class="error-msg"
                      >
                        {{ generateErrorMessage('form.newPassword') }}
                      </p>
                    </section>
                    <section>
                      <label class="form-label">
                        {{ $t('hsc.form.inputs.repeatNewPassword') }}
                        <div class="relative">
                          <input
                            v-model.trim="form.newPasswordAgain"
                            :type="
                              passwordOptions.newPasswordRepeatedVisible
                                ? 'text'
                                : 'password'
                            "
                            class="form-text password-input-padding form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="
                              $t('hsc.form.inputs.repeatNewPassword')
                            "
                          />
                          <fa
                            :icon="
                              passwordOptions.newPasswordRepeatedVisible
                                ? `${icons.basic.eyeSlash}`
                                : `${icons.basic.eye}`
                            "
                            class="password-eye-icon transitioned"
                            @click.stop="toggleNewPasswordRepeatedVisibility"
                          />
                        </div>
                      </label>
                      <p
                        v-if="generateErrorMessage('form.newPasswordAgain')"
                        class="error-msg"
                      >
                        {{ generateErrorMessage('form.newPasswordAgain') }}
                      </p>
                    </section>
                  </div>

                  <section>
                    <multiple-bordered-button
                      primary-color-name="pureYellow"
                      text-color-name="darkGrayishViolet"
                      is-submit
                      class="mt-12"
                      :disabled="status.pending"
                    >
                      <span class="btn-text">{{
                        $t('hsc.form.actions.save')
                      }}</span>
                    </multiple-bordered-button>
                  </section>
                </form>
              </controller-change-password>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultipleBorderedButton from '@/components/ui/buttons/MultipleBorderedButton'
import ControllerAddress from 'Controllers/checkout/ControllerAddress'
import ControllerOrderHistory from 'Controllers/user/ControllerOrderHistory'
import ControllerChangePassword from 'Controllers/user/ControllerChangePassword'
import vClickOutside from 'v-click-outside'
import DateFormatter from '@/mixins/DateFormatter'
import FaIcons from '@/mixins/FaIcons'
export default {
  components: {
    MultipleBorderedButton,
    ControllerOrderHistory,
    ControllerChangePassword,
    ControllerAddress,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  mixins: [FaIcons, DateFormatter],

  data() {
    return {
      profileItems: null,
      clickedItem: null,
      passwordOptions: {
        oldPasswordVisible: false,
        newPasswordVisible: false,
        newPasswordRepeatedVisible: false,
      },
    }
  },

  watch: {
    clickedItem() {
      this.scrollToTop()
    },
  },

  created() {
    const items = {
      profileSettings: 1,
      orderHistory: 2,
      passwordSettings: 3,
    }
    Object.freeze(items)
    this.profileItems = items
  },
  methods: {
    toggleClickedItem(item) {
      if (this.clickedItem === item) {
        this.clickedItem = null
      } else {
        this.clickedItem = item
      }
    },
    unsetClickedItem() {
      this.clickedItem = null
    },
    onClickOutside() {
      this.unsetClickedItem()
    },
    toggleOldPasswordVisibility() {
      this.passwordOptions.oldPasswordVisible =
        !this.passwordOptions.oldPasswordVisible
    },
    toggleNewPasswordVisibility() {
      this.passwordOptions.newPasswordVisible =
        !this.passwordOptions.newPasswordVisible
    },
    toggleNewPasswordRepeatedVisibility() {
      this.passwordOptions.newPasswordRepeatedVisible =
        !this.passwordOptions.newPasswordRepeatedVisible
    },
    async handlePasswordChangeSuccess() {
      this.unsetClickedItem()
      this.$toast.success(this.$t('hsc.profile.passwordChangeSuccess'))
      this.$router.push('/')
      await this.$auth.logout()
    },
    showSuccessToast() {
      this.$toast.success(this.$t('hsc.notifications.saved'))
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
  },
}
</script>

<style scoped>
.dropdown-activator {
  @apply flex justify-between items-center cursor-pointer;
}
.dropdown-activator-label {
  @apply text-2xl leading-tight font-pragmatica-extended;
}
.dropdown-activator-icon {
  @apply flex-shrink-0 h-12 w-12 rounded-full text-xl text-darkGrayishViolet ml-8;
}
.dropdown-activator-icon:focus {
  @apply outline-none;
}
tbody td {
  @apply py-5;
}
@media only screen and (min-width: 768px) {
  .dropdown-activator-label {
    @apply text-2.4rem;
  }
  .dropdown-activator-icon {
    @apply text-1.6rem ml-12;
  }
  tbody td {
    @apply py-1.8rem;
  }
}

@media only screen and (min-width: 1280px) {
  .dropdown-activator-icon {
    @apply ml-24;
  }
}
</style>
